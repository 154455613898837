// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-guestbook-tsx": () => import("./../src/pages/guestbook.tsx" /* webpackChunkName: "component---src-pages-guestbook-tsx" */),
  "component---src-pages-home-tsx": () => import("./../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invitation-tsx": () => import("./../src/pages/invitation.tsx" /* webpackChunkName: "component---src-pages-invitation-tsx" */),
  "component---src-pages-location-tsx": () => import("./../src/pages/location.tsx" /* webpackChunkName: "component---src-pages-location-tsx" */),
  "component---src-pages-photos-tsx": () => import("./../src/pages/photos.tsx" /* webpackChunkName: "component---src-pages-photos-tsx" */),
  "component---src-pages-vis-tsx": () => import("./../src/pages/vis.tsx" /* webpackChunkName: "component---src-pages-vis-tsx" */)
}

